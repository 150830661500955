<!--
 * @Author: your name
 * @Date: 2020-04-23 15:18:59
 * @LastEditTime: 2020-04-27 17:09:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\procDef\ProcDefNode.vue
 -->
<template>
  <div class="prode-node-wrapper">
    <el-row>
      <el-col :span="8" class="node-left">
        <el-menu
         @select="handleSelect"
         :default-active="defaultNode"
         unique-opened
          class="node-left-menu">
          <el-menu-item :index="`${item.nodeId}`" v-for="item in nodes" :key="item.nodeId" :disabled="item.type===0||item.type===2">
            <img :src="item.icons" class="node-images" alt="" srcset="">
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="16" class="node-right">
        <el-row>
          <div class="warn-tip-wrapper">
            <i class="el-icon-warning"></i> 温馨提示：若流程运行至未分配审批人员的审批节点时，流程将自动中断取消
          </div>
        </el-row>
        <el-row>
          <div class="root-name">
            <span>节点名称</span> <span style="margin-left:10px">{{node.name}}</span>
          </div>
        </el-row>
        <el-row>
          <el-form ref="form" :model="form" label-width="80px" :rules="rule">
            <el-row>
              <el-col :span="24">
                <el-form-item label="审批人" prop="materialNo">
                  <el-checkbox-group v-model="form.checkedBox" @change="onChangeBox">
                    <el-checkbox @change="onChangeRole" :checked="roleChecked" label="角色"></el-checkbox>
                    <el-checkbox @change="onChangeDept" :checked="deptChecked" label="部门"></el-checkbox>
                    <el-checkbox @change="onChangeUser" :checked="userChecked" label="用户"></el-checkbox>
                    <el-checkbox @change="onChangeDeptHeader" :checked="deptHeader" label="所属部门负责人"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="roleChecked">
                <el-form-item label="选择角色" prop="roleIds" >
                  <el-select v-model="form.roleIds" multiple placeholder="请选择" style="width:100%">
                    <el-option
                      v-for="item in roles"
                      :key="item.roleId"
                      :label="item.roleName"
                      :value="item.roleId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="deptChecked">
                <el-form-item label="部门" prop="deptIds">
                  <el-cascader
                    ref="cascader"
                    :options="depts"
                    @change="onCascader"
                    v-model="form.deptIds"
                    style="width:100%"
                    :show-all-levels="false"
                    :props="{ multiple: true, checkStrictly: true, emitPath: false }"
                    clearable></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="userChecked">
                <el-form-item label="选择用户" prop="userIds" >
                  <el-select v-model="form.userIds" multiple placeholder="请选择" style="width:100%">
                    <el-option
                      v-for="item in users"
                      :key="item.userId"
                      :label="item.userName"
                      :value="item.userId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="btn-wrapper">
      <el-button class="footer-confirm" :disabled="node.type!==1" @click="sureEvent">提交</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
      <el-button class="footer-cancel" @click="closeDialog">返回</el-button>
    </el-row>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import ProcDefNodeVue from "./procDefNodeClass";
import confirmDialog from '@/components/confirmDialog.vue'
// import pick from 'lodash.pick'

export default {
  data() {
    return {
      form: new ProcDefNodeVue('form'),
      rule: new ProcDefNodeVue('rule'),
      visible: false,
      defaultNode: "",
      confirmDialog: {
        visible: false,
        type: 1, //点击弹出提示对应组件里面的中文文字
        data: {},
      },
      mdl: {
        type: null,
      },
      deptChecked: false,
      depts: [],
      userChecked: false,
      users: [],
      roleChecked: false,
      roles: [],
      deptHeader: false,
      nodes: [],
      node: "",
    }
  },
  //部件
  components: {
    confirmDialog,
  },
  //静态
  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    ...mapState(['Language']),
  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.getNodeInfor();
    },
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      // this.defaultNode = this.confirmDialog.data.nodeId
      // this.node = this.confirmDialog.data
    },
    onCascader(value) {
      console.log("onCascader -> value", value)
    },
    sureEvent() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post(apis.nodeUpdate, {
            ...this.form,
            nodeId: this.node.nodeId,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("保存成功！")
            }
          })
        }
      })
    },
    closeDialog() {
      this.$router.push("/act/procDef")
    },
    onChangeBox(value) {
    console.log("onChangeBox -> value", value)
    },
    onChangeUser(value) {
      if (!value) {
        this.form.userIds = []
      }
      this.userChecked = value
    },
    onChangeDeptHeader(value) {
      this.deptHeader = value
    },
    onChangeRole(value) {
      if (!value) {
        this.form.roleIds = []
      }
      this.roleChecked = value
    },
    onChangeDept(value) {
      if (!value) {
        this.form.deptIds = []
      }
      this.deptChecked = value
    },
    handleSelect(nodeId) {
      console.log("handleSelect -> nodeId", nodeId)
      const node = this.nodes.filter(item => nodeId === item.nodeId)[0]
      // console.log("node", this.defaultNode)
      // if (this.node.nodeId !== nodeId && this.node) {
      //   this.confirmDialog.visible = true
      //   // 存放一下旧的nodeId的数据
      //   this.confirmDialog.data = this.node
      // }
       this.node = node;
       this.getNodeInfor()
      // console.log("handleSelect -> node", node)
    },
    /* eslint-disable */
    /**
     * 点击左侧获取相对应的数据
     */
    getNodeInfor() {
      this.$http.get(`${apis.node}/get/${this.node.nodeId}`).then((res) => {
        if (res.data.code === 0) {
          this.form.checkedBox = [];
            const data = res.data.data
            this.form = {
              ...this.form,
              ...data,
            }
            console.log("handleSelect -> data", data)
            this.mdl = Object.assign(data, this.node)
            this.roleChecked = data.roleIds.length > 0 ? this.form.checkedBox.push('角色') : false
            this.deptChecked = data.deptIds.length > 0 ? this.form.checkedBox.push('部门') : false
            this.userChecked = data.userIds.length > 0 ? this.form.checkedBox.push('用户') : false
            console.log("this.userChecked", this.userChecked)
            this.deptHeader = data.deptHeader
            // console.log('mdl:', this.mdl)
            // this.$nextTick(() => {
              // this.form = new ProcDefNodeVue('form')
              // this.roleChecked = false
              // this.deptChecked = false
              // this.userChecked = false
              // this.deptHeader = false
            // })
        }
      })
    },
    /**
     * 获取用户角色
     */
    getRoleAll() {
      this.$http.get(apis.role_all).then((res) => {
        if (res.data.code === 0) {
          this.roles = res.data.rows
        }
      })
    },
    /**
     * 获取用户部门
     */
    getDeptList() {
      this.$http.get(apis.dept_list_enable).then((res) => {
        this.buildtree(res.data.rows, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId,
            value: item.deptId,
            label: item.deptName,
            children: [],
          }
          this.buildtree(list, child.children, item.deptId)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    /**
     * 获取用户
     */
    getUserList() {
      this.$http.get(apis.userList).then((res) => {
        this.users = res.data.rows
      })
    },
    /**
     * 获取审批节点
     */
    getNodeList() {
      const proDefId = this.$route.query.procDefId
      this.$http.get(`${apis.node}/list/${proDefId}`).then((res) => {
      if (res.data.code === 0) {
          this.nodes = res.data.rows.map((item) => {
            let icon = ""
            switch (item.type) {
              case 0:
                icon = require("@/assets/images/node_begin.png")
                break
              case 2:
                icon = require("@/assets/images/node_end.png")
                break
              default:
                icon = require("@/assets/images/node_approve.png")
            }
            return {
              name: item.name,
              nodeId: item.nodeId,
              icons: icon,
              type: item.type,
            }
          })
        }
      })
    },
  },
  //请求数据
  created() {
    this.getNodeList()
  },
  mounted() {
    this.getRoleAll()
    this.getDeptList()
    this.getUserList()
    this.mdl.type = null
  },
};
</script>

<style scoped lang="less">
.prode-node-wrapper {
  margin: 20px;
  background: #fff;
  padding: 20px;
  .node-images {
    width: 24px;
    margin-right: 10px;
  }
  .root-name {
    margin: 20px 0;
    font-size: 14px;
  }
  .btn-wrapper {
    text-align: center;
    margin: 30px 0;
  }
  .node-left {
    padding-right:10px;
    border-right:1px solid rgb(228, 231, 237);
    .node-left-menu {
      max-width: 300px;
    }
  }
  .node-right {
    padding-left: 10px;
  }
}
.warn-tip-wrapper {
  text-align: center;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  padding: 8px 15px 8px 37px;
  width: 800px;
  .el-icon-warning {
    color: #faad14;
  }
}
</style>
<style lang="less">
.prode-node-wrapper {
  .el-menu-item.is-active {
    border-left: none !important;
  }
  .footer-confirm.is-disabled {
    background: #DCDFE6 !important;
    cursor: not-allowed !important;
  }
}
</style>
