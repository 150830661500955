/*
 * @Author: your name
 * @Date: 2020-04-23 18:47:42
 * @LastEditTime: 2020-04-24 10:13:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\procDef\procDefNodeClass.js
 */
import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.roleIds = []
      this.nodeId = ""
      this.userIds = []
      this.deptIds = []
      this.checkedBox = []
      this.deptHeader = false
    } else if (type === 'rule') {
      this.roleIds = [
        {
          type: 'array',
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_roleIds,
          trigger: 'blur',
        },
      ]
      this.userIds = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_userIds,
          trigger: 'blur',
        },
      ]
      this.deptIds = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_deptIds,
          trigger: 'blur',
        },
      ]
      this.purchasePrice = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_purchasePrice,
          trigger: 'blur',
        },
      ]
      this.supplierId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_supplierId,
          trigger: 'change',
        },
      ]
      this.serialNumFlag = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_serialNumFlag,
          trigger: 'change',
        },
      ]
      this.categoryId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_categoryId,
          trigger: 'change',
        },
      ]
      this.brand = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_brand,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
